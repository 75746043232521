import React from 'react';

const Advertisment = () => {
  return (
    <section className='ad-container'>
      <div className='ad-title'>super fast delivery</div>
      <div className='ad-sub-title'>place your orders now</div>
    </section>
  );
};

export default Advertisment;
